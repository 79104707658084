import React from 'react';
import Container from '../components/core/layout/Layout/Container';
import Heading from '../components/core/typography/Heading';
import List from '../components/core/lists/List';
import ListItem from '../components/core/lists/ListItem';
import { graphql, Link } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { IndemnityImagesQuery } from '../../generated/graphql-types';
import 'twin.macro';
import SEO from '../components/core/meta/SEO';
import PageHeader from '../components/views/PageHeader/PageHeader';
import { WelfareStateLeadGenerationForm } from '../components/partials/WelfareStateLeadGenerationForm/WelfareStateLeadGenerationForm';

const SUBPAGE_INDEX: { url: string; name: string }[] = [
  {
    name: 'Che cos’è',
    url: '#che-cose',
  },
  {
    name: 'Indennità di accompagnamento e legge 104',
    url: '#indennita-di-accompagnamento-e-legge-104',
  },
  {
    name: 'A quanto ammonta l’indennità di accompagnamento per disabili',
    url: '#a-quanto-ammonta-lindennita-di-accompagnamento-per-disabili',
  },
  {
    name: 'I requisiti per fare domanda di accompagnamento',
    url: '#i-requisiti-per-fare-domanda-di-accompagnamento',
  },
  {
    name: 'A chi spetta la legge 104',
    url: '#a-chi-spetta-la-legge-104',
  },
  {
    name: 'Differenze tra assegno di invalidità e pensione di invalidità',
    url: '#differenze-tra-assegno-di-invalidita-e-pensione-di-invalidita',
  },
];

const IndemnityPage = () => {
  const indemnityImages = useStaticQuery<IndemnityImagesQuery>(graphql`
    query IndemnityImages {
      doctor: file(relativePath: { eq: "doctor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      oldpeople1: file(relativePath: { eq: "indemnity/old-people1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      oldpeople2: file(relativePath: { eq: "indemnity/old-people2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Indennità di accompagnamento: ne ho diritto?"
        url="/accompagnamento-indennita"
        description="Tutto quello che devi sapere sull'indennità di accompagnamento per anziani e l'assegno sociale. Scopri tutte le curiosità!"
      />
      <PageHeader
        backgroundImage={indemnityImages.doctor.childImageSharp.fluid}
      >
        <Container>
          <Heading variant="h1" tw="text-white mb-0">
            {
              "L'INDENNITA' DI ACCOMPAGNAMENTO: A CHI SPETTA E COME FARE RICHIESTA"
            }
          </Heading>
        </Container>
      </PageHeader>
      <div tw="py-16 pb-16 lg:pb-0 lg:py-20 relative bg-primary-300">
        <Container>
          <div tw="flex justify-center">
            <div tw="w-full md:w-3/4">
              <WelfareStateLeadGenerationForm eventAction="no_flow-marketing_header_form_1" />
            </div>
          </div>
        </Container>
      </div>
      <div tw="py-16">
        <section>
          <Container>
            <div tw="flex flex-wrap -mx-4 items-center">
              <div tw="w-full md:w-1/2 px-4">
                <Image
                  alt="doctor"
                  tw="rounded-md overflow-hidden"
                  fluid={indemnityImages.doctor.childImageSharp.fluid}
                />
              </div>
              <div tw="w-full md:w-1/2 px-4">
                <Heading variant="h5">Indice</Heading>
                <List>
                  {SUBPAGE_INDEX.map(p => (
                    <ListItem key={p.url}>
                      <Link tw="font-bold text-primary-500" to={p.url}>
                        {p.name}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
          </Container>
        </section>
        <section tw="pt-16">
          <Container>
            <div tw="py-8 border-b border-gray-300">
              <Heading id="che-cose" variant="h4">
                Che cos’è l’indennità di accompagnamento
              </Heading>
              <p tw="text-paragraph">
                L’indennità di accompagnamento – detta anche assegno di
                accompagnamento – è una prestazione economica data dallo Stato
                Italiano, tramite l’INPS, destinata a coloro che risultano
                invalidi totali e/o incapaci di deambulare senza l’aiuto di un
                accompagnatore o incapaci di compiere azioni di vita quotidiana.
                L’invalido totale – ossia con invalidità del 100% – è un
                soggetto per cui è stata accertata l’impossibilità di deambulare
                in autonomia e compiere azioni di normale vita quotidiana. Ha
                dunque diritto di inoltrare una richiesta di accompagnamento.
              </p>
              <p tw="text-paragraph">
                L’indennità di accompagnamento nasce proprio per questo motivo:
                vuole essere un sostegno per il caregiver che si prende cura del
                soggetto invalido o – quando assente – vuole dare la possibilità
                all’individuo invalido di ricevere assistenza e aiuto da servizi
                esterni.
              </p>
            </div>
            <div tw="py-8">
              <Heading
                variant="h4"
                id="indennita-di-accompagnamento-e-legge-104"
              >
                Indennità di accompagnamento e legge 104
              </Heading>
              <p tw="text-paragraph mb-5">
                La legge 104, detta anche legge-quadro per l’assistenza,
                l’integrazione sociale e i diritti delle persone handicappate,
                si occupa di legiferare sulle agevolazioni riconosciute a coloro
                che sono affetti da gravi disabilità. Dal momento che numerose
                persone confondono la legge 104 con l’invalidità civile, è bene
                chiarire la differenza tra le due una volta per tutte.
              </p>
              <p tw="text-paragraph mb-5">
                Le definizioni contenute all’art. 1 della legge 104 chiariscono
                che “è persona handicappata colui che presenta una minorazione
                fisica, psichica o sensoriale, stabilizzata o progressiva, che è
                causa di difficoltà di apprendimento, di relazione o di
                integrazione lavorativa e tale da determinare un processo di
                svantaggio sociale o di emarginazione” e che “sussiste
                situazione di gravità in modo da rendere necessario un
                intervento assistenziale permanente, continuativo e globale
                nella sfera individuale o in quella di relazione”.
              </p>
              <p tw="text-paragraph mb-5">
                Con il termine invalido, invece, si fa riferimento ad una
                persona che per motivi fisici o di salute ha ripercussioni sulla
                propria capacità lavorativa.
              </p>
              <p tw="text-paragraph">
                Come suggerisce la distinzione tra le due terminologie, le due
                situazioni necessitano di due visite di accertamento specifiche
                differenti tra loro. Infatti, colui che risulta essere invalido
                al 100% non è automaticamente fruitore della Legge 104.
              </p>
            </div>
          </Container>
          <div tw="py-16 pb-16 lg:pb-0 lg:py-20 relative bg-primary-300">
            <Container>
              <div tw="flex justify-center">
                <div tw="w-full md:w-3/4">
                  <WelfareStateLeadGenerationForm eventAction="no_flow-marketing_allowance_104_section_form_1" />
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div tw="py-8 border-b border-gray-300">
              <Heading
                variant="h4"
                id="a-quanto-ammonta-lindennita-di-accompagnamento-per-disabili"
              >
                Qual è l’importo dell’indennità di accompagnamento?
              </Heading>
              <p tw="text-paragraph">
                Per il 2022, l’importo mensile riconosciuto per l’indennità di
                accompagnamento è pari <b>€ 525,17 per 12 mensilità</b>.
                L’indennità di accompagnamento è esente dalle ritenute Irpef,
                quindi non deve essere dichiarato nella dichiarazione annuale
                dei redditi. La prestazione economica viene corrisposta a
                partire dal primo giorno del mese successivo alla presentazione
                della domanda.
              </p>
            </div>
            <div tw="py-8">
              <Heading
                variant="h4"
                id="i-requisiti-per-fare-domanda-di-accompagnamento"
              >
                Requisiti per la domanda di accompagnamento
              </Heading>
              <p tw="text-paragraph">
                L’indennità di accompagnamento spetta a{' '}
                <b>anziani e disabili con totale inabilità accertata</b> (vedi
                “Come ottenere l’indennità di accompagnamento – Passaggi da
                seguire per non sbagliare). L’inabilità può riguardare la
                condizione fisica o la condizione psichica e spetta a tutti gli
                aventi diritto, senza restrizioni reddituali o di età o relative
                al nucleo familiare. L’indennità di accompagnamento spetta anche
                malati oncologici sottoposti a cicli di cure di chemioterapia, a
                patto che sia riconosciuta l’inabilità al 100% a causa della
                malattia. I requisiti fondamentali per fare richiesta di
                accompagnamento impongono di:
              </p>
              <List tw="mb-5">
                <ListItem> essere cittadini italiani;</ListItem>
                <ListItem>
                  essere cittadini stranieri comunitari iscritti all’anagrafe
                  del comune di residenza;
                </ListItem>
                <ListItem>
                  essere cittadini stranieri extracomunitari in possesso del
                  permesso di soggiorno di almeno un anno (articolo 41 Testo
                  unico immigrazione);
                </ListItem>
              </List>
              <p tw="text-paragraph mb-5">
                In ogni caso, il richiedente deve essere residente in forma
                stabile in Italia.
              </p>
              <p tw="text-paragraph">
                Ulteriori requisiti per effettuare la{' '}
                <b>richiesta per l’indennità di accompagnamento</b> sono:
              </p>
              <List>
                <ListItem>
                  Una certificazione dell’invalidità totale o permanente per i
                  richiedenti compresi tra i 18 e i 65 anni;
                </ListItem>
                <ListItem>
                  Per i cittadini minorenni o per gli anziani che hanno superato
                  la soglia dei 65 anni di età, è sufficiente che all’interno
                  del verbale, redatto in seguito alla visita medica di
                  accertamento sanitario, sia riportato che al soggetto o
                  risulti impossibile deambulare senza un aiuto costante di un
                  accompagnatore o sia impossibile compiere gli atti quotidiani
                  della vita senza la disposizione di un’assistenza costante;
                </ListItem>
                <ListItem>
                  <b>
                    Non essere ricoverati presso strutture a pagamento aventi la
                    retta a carico dello Stato o di Enti pubblici.
                  </b>
                </ListItem>
              </List>
            </div>
          </Container>
          <div tw="py-16 pb-16 lg:pb-0 lg:py-20 relative bg-primary-300">
            <Container>
              <div tw="flex justify-center">
                <div tw="w-full md:w-3/4">
                  <WelfareStateLeadGenerationForm eventAction="no_flow-marketing_requirements_section_form_1" />
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div tw="py-8">
              <Heading variant="h4" id="a-chi-spetta-la-legge-104">
                A chi spetta la legge 104
              </Heading>
              <p tw="text-paragraph mb-5">
                Chi è <b>fruitore della legge 104</b> può godere di ore o giorni
                di permesso retribuiti. Per quanto concerne i lavoratori
                disabili, essi hanno la possibilità di richiedere, consecutivi o
                suddivisi mensilmente, 2 ore al giorno o 3 giorni. Il lavoratore
                dispone della possibilità di richiedere variazioni in merito
                alla suddivisione di tali permessi; le suddette modifiche
                possono entrare in vigore il mese successivo o, nei casi di
                urgenza o emergenza, anche il mese in corso.
              </p>
              <p tw="text-paragraph mb-5">
                Naturalmente, poiché tale sostegno spetta non solo ai disabili
                ma anche ai loro familiari, i permessi retribuiti sono forniti
                anche ai caregiver, ossia i membri della famiglia che si
                prendono cura della persona affetta da disabilità.
              </p>
              <p tw="text-paragraph">
                Lo <b>stato di disabilità</b> deve essere certificato attraverso
                una visita medica al seguito della quale verrà rilasciato
                l’apposito certificato del medico curante. Una volta ottenuto il
                suddetto certificato, per ottenere l’indennità di
                accompagnamento esso dovrà essere inviato all’INPS, i cui tempi
                di erogazione dello stato di indennità ammontano ad un massimo
                di 90 giorni. Nel corso di tale periodo, verrà effettuato un
                accertamento dello stato di salute del richiedente da parte di
                una commissione medica dell’ASL.
              </p>
            </div>
          </Container>
          <div tw="py-16 pb-16 lg:pb-0 lg:py-20 relative bg-primary-300">
            <Container>
              <div tw="flex justify-center">
                <div tw="w-full md:w-3/4">
                  <WelfareStateLeadGenerationForm eventAction="no_flow-marketing_responsibility_104_section_form_1" />
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div tw="py-8">
              <Heading
                variant="h4"
                id="differenze-tra-assegno-di-invalidita-e-pensione-di-invalidita"
              >
                Differenze tra assegno di invalidità e pensione di invalidità
              </Heading>
              <p tw="text-paragraph">
                L’assegno di invalidità è una prestazione economica che viene
                rilasciata mensilmente a coloro che risultano essere invalidi
                parziali di un’età compresa tra i 18 e i 67 anni (termini che
                possono essere modificati in relazione alla revisione periodica
                da parte del Governo). La pensione di invalidità, invece, è
                destinata a coloro che sono invalidi al 100% e che rientrano nel
                medesimo range di età.
              </p>
            </div>
          </Container>
        </section>
        <section tw="bg-primary-300 py-16">
          <Container>
            <Heading variant="h5">
              Come ottenere l’indennità di accompagnamento – Passaggi da seguire
              per non sbagliare
            </Heading>
            <p tw="text-paragraph mb-10">
              L’ente preposto all’erogazione dell’indennità di accompagnamento è
              l’INPS, che ne conferma l’ammissibilità sulla base della
              documentazione ricevuta. Vediamo quindi tutti i passaggi per
              ottenere la documentazione e l’indennità di accompagnamento per
              anziani e disabili.
            </p>
            <div tw="mb-10">
              <Heading variant="h5" tw="mb-10">
                Richiedere il riconoscimento di invalidità.
              </Heading>
              <div tw="rounded-md bg-white p-5 shadow-lg md:p-10">
                <p tw="text-paragraph">
                  <b>
                    Prendere appuntamento con il proprio medico di famiglia{' '}
                  </b>
                  Al proprio medico è necessario richiedere che venga rilasciato
                  il <b>Certificato Medico Introduttivo</b>. In questo modo si
                  sta richiedendo che avvenga <b>l’Accertamento Sanitario</b>,
                  necessario per riconoscere l’invalidità del richiedente.
                  All’interno del documento il Medico deve indicare i dati
                  anagrafici della persona, la natura della patologia per cui si
                  richiede il riconoscimento dell’invalidità e la prognosi.
                  Alcune informazioni da inserire nel certificato sono ad
                  esempio:
                </p>
                <List tw="mb-5">
                  <ListItem>
                    Impossibilità di deambulare senza l’aiuto di un
                    accompagnatore;
                  </ListItem>
                  <ListItem>
                    Impossibilità di compiere gli atti quotidiani della vita
                    senza assistenza;
                  </ListItem>
                  <ListItem>
                    Soggetto affetto da patologia in situazione di gravità;
                  </ListItem>
                  <ListItem>
                    Soggetto affetto da patologia di competenza Anffas
                    (disabilità intellettiva o relazionale);
                  </ListItem>
                  <ListItem>
                    Soggetto affetto da malattia neoplastica (tumore);{' '}
                  </ListItem>
                </List>
                <p tw="underline font-bold">Documenti da avere con sé:</p>
                <List>
                  <ListItem>Documento di identità del richiedente</ListItem>
                  <ListItem>Codice fiscale del richiedente</ListItem>
                  <ListItem>Tessera sanitaria del richiedente</ListItem>
                </List>
              </div>
            </div>
            <div tw="mb-10">
              <Heading variant="h5" tw="mb-10">
                Richiedere l’indennità di accompagnamento
              </Heading>
              <div tw="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p tw="text-paragraph">
                  <b>
                    Inviare il Certificato Medico Introduttivo all’INPS e
                    generazione codice identificativo della richiesta
                  </b>
                  . Il certificato viene redatto in formato digitale. É compito
                  del medico di famiglia inoltrarlo telematicamente all’INPS
                  tramite l’apposita funzione che si trova sul sito internet
                  dell’ente. Al momento dell’invio, viene rilasciata una
                  ricevuta con un codice identificativo che certifica
                  l’avviamento della procedura. Questa ricevuta viene consegnata
                  dal medico al paziente che richiede l’indennità di
                  accompagnamento,{' '}
                  <b>insieme al certificato introduttivo originale</b>.
                </p>
              </div>
              <div tw="rounded-md bg-white p-5 shadow-lg md:p-10">
                <p tw="text-paragraph">
                  <b>
                    Fare la visita medica per il riconoscimento dell’invalidità
                  </b>{' '}
                  Il Certificato Medico Introduttivo ha una validità di 30
                  giorni. Il malato viene contattato dall’INPS per la{' '}
                  <b>visita medica che attesti l’invalidità della persona</b>.
                  La visita può essere effettuata presso l’ASL oppure a
                  domicilio. In questo caso è importante ricordarsi di
                  segnalarlo all’interno del Certificato Medico Introduttivo. Al
                  termine della visita medica, il medico rilascia il{' '}
                  <b>certificato di invalidità</b>.
                </p>
                <p tw="underline font-bold"> Documenti da avere con sé: </p>
                <List>
                  <ListItem>
                    Certificato inviato all’INPS firmato dal medico di base
                    abilitato
                  </ListItem>
                  <ListItem>
                    Ricevuta di invio – Contenente il codice identificativo
                    della richiesta
                  </ListItem>
                  <ListItem>Documento di identità del richiedente</ListItem>
                  <ListItem>Codice fiscale del richiedente</ListItem>
                  <ListItem>Tessera sanitaria del richiedente</ListItem>
                  <ListItem>
                    Originale e copia della documentazione sanitaria a supporto
                    delle patologie indicate dal medico di base nel certificato
                    inviato all’INPS
                  </ListItem>
                </List>
              </div>
            </div>
            <div>
              <Heading variant="h5" tw="mb-10">
                Richiedere l’erogazione dell’indennità di accompagnamento.
              </Heading>
              <div tw="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p tw="text-paragraph mb-5">
                  <b>
                    Presentazione della domanda per l'ottenimento dell’indennità
                    di accompagnamento
                  </b>{' '}
                  È possibile presentare la domanda – inoltrando il certificato
                  di invalidità – in due modalità.
                </p>
                <p tw="text-paragraph">
                  Attraverso il sito web: Per poter utilizzare questo canale è
                  necessario essere in possesso del PIN rilasciato dall’INPS. Se
                  non lo si dispone, si può fare richiesta tramite il sito o
                  chiamando il numero 803164. Con il codice PIN a disposizione è
                  sufficiente:
                </p>
                <List>
                  <ListItem>
                    Accedere al sito:{' '}
                    <a
                      href="http://www.inps.it/"
                      tw="font-bold text-primary-500"
                    >
                      dell’INPS
                    </a>{' '}
                  </ListItem>
                  <ListItem>Cliccare nella sezione “Tutti i servizi”</ListItem>
                  <ListItem>
                    Ricercare la sezione: “Invalidità civile – Invio domanda di
                    riconoscimento dei requisiti sanitari”
                  </ListItem>
                  <ListItem>
                    Compilare la domanda e procedere con l’invio
                  </ListItem>
                </List>
                <p tw="text-paragraph mb-5">
                  <b>Attraverso enti di patronato</b> o tramite{' '}
                  <b>associazioni di categoria per disabili</b>, come ANMIC,
                  ENS, UIC, ANFASS: in questo caso è necessario recarsi presso
                  la struttura più vicina, consegnare il certificato e compilare
                  la domanda con l’ausilio del personale preposto.
                </p>
              </div>
              <div tw="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p tw="text-paragraph">
                  <b>
                    Ricezione del verbale di invalidità civile da parte
                    dell’INPS
                  </b>{' '}
                  Dopo aver visionato la documentazione, l’INPS invia tramite
                  PEC – se fornita in fase di compilazione della domanda – o
                  tramite raccomandata A/R il verbale di invalidità civile.
                </p>
              </div>
              <div tw="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p tw="text-paragraph">
                  <b>Presentazione del modulo AP70</b> Come per la precedente
                  domanda, è possibile accedere alla richiesta via sito web o
                  tramite gli enti di patronato. Il modulo da compilare e
                  inviare è il seguente: “Invalidità civile – Invio dati
                  socio-economici e reddituali per la concessione delle
                  prestazioni economiche”, scaricabile sempre dal sito
                  dell’INPS
                </p>
              </div>
              <div tw="rounded-md bg-white p-5 shadow-lg md:p-10">
                <p tw="text-paragraph font-bold">
                  Ricezione dell’indennizzo di accompagnamento
                </p>
              </div>
            </div>
          </Container>
          <div tw="py-16 pb-0 lg:pb-0 lg:pt-20 relative bg-primary-300">
            <Container>
              <div tw="flex justify-center">
                <div tw="w-full md:w-3/4">
                  <WelfareStateLeadGenerationForm eventAction="no_flow-marketing_steps_section_form_1" />
                </div>
              </div>
            </Container>
          </div>
        </section>
        <section tw="py-12">
          <Container>
            <Heading variant="h5" tw="mb-10">
              INFORMAZIONI AGGIUNTIVE SULL’INDENNITA’ DI ACCOMPAGNAMENTO
            </Heading>
            <div tw="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Indennità di accompagnamento e compatibilità con pensione di
                invalidità o con l’attività lavorativa
              </Heading>
              <p tw="text-paragraph">
                L’indennità di accompagnamento e la pensione di invalidità sono
                compatibili e cumulabili. Non solo: l’indennità di
                accompagnamento risulta compatibile anche con lo svolgimento di
                attività lavorativa, dipendente o autonoma, e con la titolarità
                di una patente speciale.
              </p>
            </div>
            <div tw="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Indennità di accompagnamento: incompatibilità
              </Heading>
              <p tw="text-paragraph">
                L’indennità di accompagnamento non spetta a coloro che:
              </p>
              <List>
                <ListItem>
                  sono ricoverati gratuitamente in istituto di cura per un
                  periodo superiore a 30 giorni;
                </ListItem>
                <ListItem>
                  percepiscono un’analoga indennità per invalidità contratta per
                  causa di guerra, di lavoro o di servizio.
                </ListItem>
              </List>
            </div>
            <div tw="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Rinnovo annuale dell’indennità di accompagnamento
              </Heading>
              <p tw="text-paragraph">
                Una volta ottenuta l’indennità, gli interessati dovranno
                produrre annualmente – entro il 31 marzo di ciascun anno – una
                dichiarazione di responsabilità (ICRIC) attestante la permanenza
                dell’invalidità totale
              </p>
            </div>
            <div tw="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Cosa fare in caso non venga riconosciuta l’indennità di
                accompagnamento
              </Heading>
              <p tw="text-paragraph">
                In caso il richiedente riceva il verbale di non riconoscimento
                dell’indennità di accompagnamento o non ne venga riconosciuta
                l’indennità, è possibile presentare ricorso. A tal fine il
                richiedente deve prima sottoporsi a un accertamento tecnico
                preventivo, cioè a una verifica preventiva delle condizioni
                sanitarie a sostegno della propria richiesta. In sua mancanza,
                il ricorso non può proseguire. L’accertamento preventivo è
                effettuato, a spese del richiedente, da un consulente tecnico
                nominato dal giudice, in presenza di un medico legale dell’Inps:
                al termine è poi redatta una relazione tecnica, che è trasmessa
                all’Inps e al richiedente. La relazione può essere contestata
                entro 30 giorni: in mancanza di opposizione, viene omologata dal
                Giudice con decreto inappellabile. Chi contesta la relazione
                deve presentare, entro 30 giorni dall’opposizione, il ricorso
                introduttivo del giudizio, specificandone i motivi: da quel
                momento inizia il giudizio vero e proprio, che si conclude con
                una sentenza inappellabile.
              </p>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default IndemnityPage;
